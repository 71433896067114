import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { Article } from "~/models";
import type { APIParams, APIResponse } from "~/models/Api";

class ArticlesModule extends HttpFactory {
  private resourcePath = "/api/articles";

  async list(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<Article[]>>,
  ) {
    return await useAsyncData<APIResponse<Article[]>>(
      "articles",
      () => {
        const params = {
          populate: {
            banner: true,
            tags: true,
            article_groups: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async findOneBySlug<T = Article, R = T>(
    {
      slug,
    }: {
      slug?: Ref<string> | string;
    },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      "articleBySlug",
      () => {
        const params = {
          relations: "relatedArticles,siblingArticles",
        };

        const url = `${this.resourcePath}/${slug}`;

        return this.call({
          method: "GET",
          url,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async getByTagsSlug(
    {
      key,
      slugs,
      pagination,
    }: {
      key?: string;
      slugs?: Ref<string[]> | string[];
      pagination?: APIParams["pagination"];
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<Article[]>>,
  ) {
    return await useAsyncData<APIResponse<Article[]>>(
      key ?? "articlesBySlug",
      () => {
        const params = {
          populate: {
            banner: true,
            tags: true,
          },
          filters: {
            tags: {
              slug: {
                $in: isRef(slugs) ? slugs.value : slugs,
              },
            },
          },
          pagination: isRef(pagination) ? pagination.value : pagination,
          sort: "publishedAt:desc",
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default ArticlesModule;
